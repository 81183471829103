<template>
  <div class="flex flex-row flex-wrap items-center">
    <a-row>
      <a-col
        v-for="(getaway, getawayIndex) in getaways"
        :key="getawayIndex"
        :lg="6"
        :sm="6"
        :xs="24"
        class="p-2"
      >
        <div
          class="language-card flex-1 h-full items-center justify-center"
          :class="{ 'language-card_active': value && getaway.code === value.code }"
          :title="getaway.name"
          @click="$emit('input', getaway)"
        >
          <selected-icon
            class="selected-icon"
            :active="value && getaway.code === value.code"
          />
          <div class="flex flex-col items-center justify-center">
            <a-avatar
              class="m-0"
              :size="100"
              :src="getawayImage(getaway.code)"
            />
          </div>
          <div class="leading-tight mt-2">
            <p class="font-medium text-center text-xs">
              {{ getaway.name }}
            </p>
          </div>
        </div>
      </a-col>
    </a-row>

  </div>
</template>

<script>
import Api from '@/includes/logic/Api'
import SelectedIcon from '@/components/chat-adding/SelectedIcon'
import { errorNotification } from "@/includes/NotificationService";

export default {
  name: "SelectGetaway",

  props: [ 'chat', 'tariff', 'value' ],

  components: {
    SelectedIcon
  },

  data() {
    return {
      fullTariff: null,
    }
  },

  computed: {
    getaways() {
      if (this.fullTariff === null) return

      if (this.fullTariff.shops.local !== undefined) {
        return this.fullTariff.shops.local.slice().sort((a, b) => b.weight > a.weight)
      }

      if (this.fullTariff.shops.global !== undefined) {
        return this.fullTariff.shops.global.slice().sort((a, b) => b.weight > a.weight)
      }
    }
  },

  methods: {
    getawayImage(code) {
      return `https://client.chatkeeper.app/assets/getaways/${ code }.png`
    },
  },

  created() {
    Api.getPayMethods("tg", {
      chat_id: this.chat.chat_id,
      target: 'chat',
    })
      .then(({ data }) => {
        data.tariffs.forEach((tariff) => {
          if (
            tariff.name === this.tariff.name &&
            tariff.monthCount === this.tariff.packageCount
          ) {
            this.fullTariff = tariff
          }
        })
        // console.log(data)
      })
      .catch(errorNotification)
    // this.items = res.data.tariffs;
    // this.$vs.loading.close(`#loading-container-${this.$route.params.type} > .con-vs-loading`);
  }
}
</script>

<style scoped lang="scss">
@import 'styles';
</style>
